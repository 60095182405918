import React from "react";
import { withFormik } from "formik";
import * as Yup from "yup";
import { withStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Box from '@material-ui/core/Box';
import axios from "axios";

import MuiAlert from '@material-ui/lab/Alert';

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    contactForm: {
        [theme.breakpoints.up('sm')]: {
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            width: '100%'
        },
        [theme.breakpoints.up('lg')]: {
            width: '85%'
        },
        paddingBottom: '10%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    contactFormItem: {
    },
    buttonSend: {
        float: 'right',
        backgroundColor: '#43A6C6',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#2E8BC0',
            color: '#FFF'
        }
    },
    buttonClear: {
        float: 'left',
        marginLeft: '3%',
        backgroundColor: 'lightgrey',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#2E8BC0',
            color: '#FFF'
        }
    },
    contactAlertNone: {
        [theme.breakpoints.up('xs')]: {
            marginTop: '-15%'
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: '-8%'
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '-8%'
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: '-2%'
        },
    },
    contactAlert: {
        [theme.breakpoints.up('xs')]: {
            width: '100%',
            marginBottom: '1%',
            marginTop: '-4%'
        },
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            marginBottom: '1%',
            marginTop: '-3%'
        },
        [theme.breakpoints.up('md')]: {
            width: '90%',
            marginBottom: '1%',
            marginTop: '-2%'
        },
        [theme.breakpoints.up('lg')]: {
            width: '50%',
            marginBottom: '1%',
            marginTop: '-2%'
        },
        marginLeft: 'auto',
        marginRight: 'auto',
        justifyContent: 'center'
    },
    heading: {
        [theme.breakpoints.up('sm')]: {
        },
        [theme.breakpoints.up('md')]: {
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: '7%'
        },

    },
    alertTimer: {
        display: 'none'
    }
})

const subject = [
    {
        value: "webDevelopment",
        label: "Web Development"
    },
    {
        value: "freelanceTesting",
        label: "Freelance Testing"
    },
    {
        value: "courseCreation",
        label: "Course Creation"
    },
    {
        value: "articleCreation",
        label: "Article Creation"
    },
    {
        value: "jobOffer",
        label: "Job Offer"
    },
    {
        value: "question",
        label: "Question"
    },
    {
        value: "other",
        label: "Other"
    }
];

const contactForm = props => {
    let message = props.status ? props.status : ''

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    function ShowMessage({ submitStatus }) {
        if (submitStatus === '') {
            return (
                <p className={classes.contactAlertNone}>
                </p >
            )
        } else if (submitStatus) {
            return (
                <div>
                    <div className={classes.alertTimer}>
                        {
                            setTimeout(() => {
                                {
                                    setStatus('')
                                    ShowMessage(message)
                                }
                            }, 8000)
                        }
                    </div>
                    <Alert severity="success" className={classes.contactAlert}>
                        Your message was sucessfully sent to Julia Pottinger
                </Alert>
                </div>
            )
        }
        else {
            return (
                <Alert severity="error" className={classes.contactAlert}>
                    Form submission unsuccessful. Please try again
                </Alert>
            )
        }
    }

    const {
        classes,
        values,
        touched,
        errors,
        setStatus,
        status,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset
    } = props;
    return (
        <div >
            <div>
                <ShowMessage submitStatus={message} />
            </div>
            <h1 className={classes.heading}
                style={
                    {
                        marginTop: 0,
                    }
                }>
                Contact Julia
            </h1>
            <Box className={classes.contactForm}>
                <form onSubmit={handleSubmit} className={classes.root} id="contactForm" name='contactForm' >
                    <div>
                        Full Name
                        <TextField
                            className={classes.contactFormItem}
                            name="name"
                            id="name"
                            placeholder='Julia Pottinger'
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.name ? errors.name : ""}
                            error={touched.name && Boolean(errors.name)}
                            margin="dense"
                            variant="outlined"
                        />
                    </div>
                    <div className={classes.email}>
                        Email
                            <TextField
                            className={classes.contactFormItem}
                            id="email"
                            type="email"
                            name="email"
                            placeholder='julia@pottinger.com'
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.email ? errors.email : ""}
                            error={touched.email && Boolean(errors.email)}
                            margin="dense"
                            variant="outlined"
                        />
                    </div>
                    <div>
                        Subject
                        <TextField
                            className={classes.contactFormItem}
                            select
                            name="subject"
                            label='Reason for contact'
                            id="subject"
                            value={values.subject}
                            onChange={handleChange("subject")}
                            helperText={touched.subject ? errors.subject : ""}
                            error={touched.subject && Boolean(errors.subject)}
                            margin="dense"
                            variant="outlined"
                        >
                            {subject.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div>
                        Message
                        <TextField
                            className={classes.contactFormItem}
                            multiline={true}
                            id="message"
                            type="message"
                            name="message"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.message ? errors.message : ""}
                            error={touched.message && Boolean(errors.message)}
                            margin="dense"
                            variant="outlined"
                            placeholder='Message that you want to send to Julia'
                            rows={10}
                        />
                    </div>
                    <Button type="submit" variant="contained" disabled={isSubmitting} className={classes.buttonSend}>
                        SUBMIT
                            </Button>
                    <Button type="reset" variant="contained" onClick={handleReset} className={classes.buttonClear} >
                        CLEAR
                            </Button>
                </form>
            </Box>
        </div >
    );
};

const ConForm = withFormik({
    mapPropsToValues: ({
        name,
        email,
        subject,
        message
    }) => {
        return {
            name: name || "",
            email: email || "",
            subject: subject || "",
            message: message || "",
        };
    },

    validationSchema: Yup.object().shape({
        name: Yup.string()
            .required("Required")
            .min(6, 'FullName must contain at least 6 characters'),
        email: Yup.string()
            .email("Enter a valid email")
            .required("Email is required"),
        subject: Yup.string().required("You must select a reason for contact"),
        message: Yup.string()
            .min(25, "Message must contain at least 25 characters")
            .required("Enter your message"),
    }),

    handleSubmit: (values, { status, setStatus, setSubmitting }) => {

        var xhr = new XMLHttpRequest();
        var url = 'https://api.hsforms.com/submissions/v3/integration/submit/7646304/1eaad2a3-ba24-42ff-9a28-6e57865c0afc'
        var data = {
            "fields": [
                {
                    "name": "email",
                    "value": values.email
                },
                {
                    "name": "firstname",
                    "value": values.name
                },
                {
                    "name": "subject",
                    "value": values.subject
                },
                {
                    "name": "message",
                    "value": values.message
                }
            ],
            "context": {
                "pageUri": "www.juliapottinger.com/contact",
                "pageName": "Julia Pottinger Contact"
            },
        }

        var final_data = JSON.stringify(data)

        xhr.open('POST', url);
        // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                // alert(xhr.responseText); // Returns a 200 response if the submission is successful.
                setStatus(true)
            } else if (xhr.readyState === 4 && xhr.status === 403) {
                // alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.       
                setStatus(false)
            } else if (xhr.readyState === 4 && xhr.status === 404) {
                // alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found     
                setStatus(false)
            }
        }

        setSubmitting(false);
        // Sends the request 
        xhr.send(final_data)
    }
})(contactForm);

export default withStyles(styles)(ConForm);
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeHeader from '../components/homeHeader'
import contactBackground from '../../static/newContactBackground.jpg'
import ContactForm from "../components/contactForm"
import contactBackgroundMobile from '../../static/ContactMobileBackground.jpg'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  mobile: {
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    },
  },
  desktop: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  },
})
)

const Contact = () => {
  const classes = useStyles()

  return (
    <div style={{ alignItems: 'center' }}>
      <div className={classes.desktop} >
        <HomeHeader pic={contactBackground} text='' />
      </div>
      <div className={classes.mobile} >
        <HomeHeader pic={contactBackgroundMobile} text='' />
      </div>
      <Layout>
        <SEO title='Contact' />
        <ContactForm />
      </Layout>
    </div>
  )
}

export default Contact